<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: harvestActionDialog.vue
Description: This file is the harvest action dialog box contains form for adding, editing the harvest details
-->
<template>
  <er-dialog
    width="30%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @open="initComponent"
    @beforeClose="handleCloseDialog"
    :title="
      `${actionToPerform === 'edit' ? $t('Comn_edit') : $t('Comn_create')} ${$t(
        'Comn_harvest'
      )}`
    "
    class="action-on-harvest-log"
    custom-class="harvest-action-dialog"
  >
    <el-alert
      v-show="ehm__unhandledErrorMessage !== ''"
      :title="ehm__unhandledErrorMessage"
      type="warning"
    ></el-alert>
    <el-form size="small" :model="formHarvestLog">
      <el-form-item
        :label="formStrings.pond_name.label"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.pond_id)"
      >
        <el-input
          v-if="actionToPerform === 'edit'"
          :value="
            this.formHarvestLog.pond_id
              ? this.formHarvestLog.pond_id.title
              : 'No title'
          "
          disabled
        >
          <!-- {{
            (
            formHarvestLog.pond_id || {
            title: "No title",
            }
            ).title
            }}-->
        </el-input>

        <er-select
          v-else
          v-model="formHarvestLog.pond_id"
          class="select-harvest-log-pond fit-width"
          value-key="_id"
          filterable
          :no-data-text="$t('Comn_culture_not_started_msg')"
          :placeholder="formStrings.pond_name.placeholder"
          @change="handleSelectPond"
        >
          <el-option
            v-for="(pond, index) in pondsInCulure"
            :key="index"
            :label="pond.title"
            :value="pond._id"
          ></el-option>
        </er-select>
      </el-form-item>
      <el-form-item
        :label="formStrings.date.label"
        class="input-name"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.date)"
      >
        <el-col :span="20">
          <el-date-picker
            class="date-picker-harvest-date fit-width"
            type="date"
            :clearable="false"
            :format="this.upm__getFormatDateString"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            v-model="formHarvestLog.date"
            :disabled="formHarvestLog.pond_id === '' || disabledDate"
          ></el-date-picker>
        </el-col>
        <!--  -->
      </el-form-item>
      <el-form-item
        :label="formStrings.abw.label"
        class="input-feed-type"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.abw)"
      >
        <el-col :span="20">
          <el-input-number
            v-model="formHarvestLog.abw"
            :placeholder="formStrings.abw.placeholder"
            :min="0"
            class="input-abw fit-width"
            :controls="false"
          ></el-input-number>
        </el-col>
      </el-form-item>
      <el-form-item
        :label="formStrings.biomass.label"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.biomass)"
      >
        <div style="display: flex">
          <div>
            <el-input-number
              class="input-biomass"
              v-model="formHarvestLog.biomass"
              :controls="false"
              :min="0"
            ></el-input-number>
          </div>
          <div>
            <er-select
              v-model="formHarvestLog.biomass_units"
              :placeholder="$t('Comn_units')"
              class="input-biomass-units"
            >
              <el-option value="KG" :label="$t('Comn_kg')">{{
                $t("Comn_kg")
              }}</el-option>
              <el-option value="L" :label="$t('Comn_lb')">{{
                $t("Comn_lb")
              }}</el-option>
            </er-select>
          </div>
        </div>
      </el-form-item>
      <!-- :error="survival_percentageError" -->
      <el-form-item
        :label="formStrings.survival_percentage.label"
        class="form-survival-error"
        :error="
          ekmm__castErrorKeyToLang(ehm__errMessagesObject.survival_percentage)
        "
      >
        <el-input-number
          class="input-survival_percentage fit-width"
          :controls="false"
          :min="0"
          v-model="formHarvestLog.survival_percentage"
          :placeholder="formStrings.survival_percentage.placeholder"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label="formStrings.cumulative_feed.label"
        :error="
          ekmm__castErrorKeyToLang(ehm__errMessagesObject.cumulative_feed)
        "
      >
        <er-select class="fit-width" v-model="formHarvestLog.cumulative_feed">
          <el-option
            v-for="(value, key) in CumulativeValue"
            :key="key"
            :label="value"
            :value="key"
          ></el-option>
        </er-select>
      </el-form-item>
      <el-form-item
        :label="formStrings.type.label"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.type)"
      >
        <er-select
          class="select-harvest-type fit-width"
          v-model="formHarvestLog.type"
          filterable
          :placeholder="formStrings.type.placeholder"
          :disabled="harvestLog.type === 'FINAL' && actionToPerform === 'edit'"
        >
          <el-option
            v-for="(value, key) in statusType"
            :key="key"
            :label="value"
            :value="key"
          ></el-option>
        </er-select>
      </el-form-item>
      <el-form-item
        :label="formStrings.fcr.label"
        v-show="formHarvestLog.type === 'FINAL'"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.fcr)"
      >
        <el-input-number
          class="input-fcr fit-width"
          :controls="false"
          v-model="formHarvestLog.fcr"
          :placeholder="formStrings.fcr.placeholder"
        ></el-input-number>
      </el-form-item>
    </el-form>

    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="submitHarvestLogDetails"
        :disabled="disabledDate || isReadOnly"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog('close')"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { mapGetters } from "vuex";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";

export default {
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    errorKeyMapMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  props: {
    harvestLog: {
      default: () => ({
        abw: 0,
        biomass: 0,
        cumulative_feed: "",
        date: "",
        fcr: 0,
        survival_percentage: 0,
        pond_name: "",
        post_larva_stocked: "",
        start_of_cultivation: "",
        survival_rate: 0,
        type: "PARTIAL"
      })
    },
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    }
  },
  data: function() {
    return {
      formHarvestLog: {},
      ehm__errMessagesObject: {
        abw: "",
        biomass: "",
        cumulative_feed: {},
        date: "",
        fcr: "",
        survival_percentage: '',
        pond_id: "",
        post_larva_stocked: "",
        start_of_cultivation: "",
        survival_rate: "",
        type: ""
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    newHarvestLog() {
      return {
        abw: "",
        biomass: "",
        cumulative_feed: "",
        date: "",
        fcr: "",
        survival_percentage: "",
        pond_id: "",
        post_larva_stocked: "",
        start_of_cultivation: "",
        survival_rate: "",
        type: ""
      };
    },
    pickerOptions() {
      return {
        disabledDate: time => {
          let cultivation_date = (
            this.getMapPondidPond.get(this.formHarvestLog.pond_id) || {
              cultivation_date: this.dhm__dateUtilsLib.minTimeISO
            }
          ).cultivation_date;
          if (this.actionToPerform === "edit") {
            cultivation_date = this.formHarvestLog.start_of_cultivation;
          }
          const computedCultivationDate = this.dhm__dateUtilsLib.utcToZonedTime(
            this.dhm__castUserUTCToUTCDateObj(cultivation_date),
            this.getUserTimeZoneString
          );
          return (
            this.dhm__dateUtilsLib.isBefore(time, computedCultivationDate) ||
            this.dhm__dateUtilsLib.isAfter(time, this.dhm__getTodayInUserTZ)
          );
        }
      };
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },
    pondsInCulure() {
      return this.$store.getters["pond/getPondsInCulture"];
    },
    getMapPondidPond() {
      return this.$store.getters["pond/getMapPondidPond"];
    },
    statusType() {
      return {
        FINAL: this.$t("final"),
        PARTIAL: this.$t("partial")
      };
    },
    CumulativeValue() {
      return {
        MANUAL: this.$t("Comn_manual"),
        POND_MOTHER: this.$t("Comn_pond_mother"),
        MANUAL_AND_POND_MOTHER:
          this.$t("Comn_manual") + this.$t("and") + this.$t("Comn_pond_mother")
      };
    },
    disabledDate() {
      const computedCultivationDate = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCDateObj(
          (
            this.getMapPondidPond.get(this.formHarvestLog.pond_id) || {
              cultivation_date: this.dhm__dateUtilsLib.minTimeISO
            }
          ).cultivation_date
        ),
        this.getUserTimeZoneString
      );
      if (this.getMapPondidPond.get(this.formHarvestLog.pond_id)) {
        return this.dhm__dateUtilsLib.isEqual(
          this.dhm__getTodayInUserTZ,
          computedCultivationDate
        );
      }
      return false;
    },
    formStrings() {
      return {
        pond_name: {
          label: this.$t("Comn_pond_name"),
          placeholder: this.$t("Ponds_select_pond_name")
        },
        date: {
          label: this.$t("Harvest_date"),
          placeholder: this.$t("Harvest_date")
        },
        abw: {
          label: this.$t("ABW_abw"),
          placeholder: this.$t("ABW_abw")
        },
        biomass: {
          label: this.$t("Comn_biomass"),
          placeholder: this.$t("Comn_biomass")
        },
        fcr: {
          label: this.$t("Comn_fcr"),
          placeholder: this.$t("Comn_fcr")
        },
        survival_percentage: {
          label: this.$t("Comn_survival_percentage"),
          placeholder: this.$t("Comn_survival_percentage")
        },
        cumulative_feed: {
          label: this.$t("Comn_cumu_feed"),
          placeholder: this.$t("Comn_cumu_feed")
        },
        type: {
          label: this.$t("Comn_status"),
          placeholder: this.$t("Comn_status")
        }
      };
    }
  },
  methods: {
    handleCloseDialog(event) {
      this.$emit("close_dialog", event);
    },
    initComponent() {
      this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newHarvestLog);
      console.log(this.ehm__errorMessagesObject);
      this.ehm__unhandledErrorMessage = "";
      this.formHarvestLog = this.$lodash.cloneDeep(this.harvestLog);
    },
    handleSelectPond(pond) {},
    async submitHarvestLogDetails() {
      this.loading = true;
      try {
        const {
          pond_id,
          abw,
          biomass,
          type,
          fcr,
          survival_percentage,
          date,
          cumulative_feed,
          biomass_units
        } = this.formHarvestLog;
        const payload = {
          pond_id,
          abw,
          biomass,
          type,
          date,
          survival_percentage,
          cumulative_feed,
          biomass_units
        };
        if (type === "FINAL") {
          payload.fcr = fcr;
        }
        let storeAction = "";
        this.ehm__errMessagesObject = this.$lodash.cloneDeep(
          this.newHarvestLog
        );
        this.ehm__unhandledErrorMessage = "";
        if (this.actionToPerform === "edit") {
          payload.harvest_id = this.formHarvestLog._id;
          storeAction = "pond/updateHarvestLog";
        } else {
          storeAction = "pond/saveHarvestLog";
        }
        const response = await this.$store.dispatch(storeAction, payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t(
            this.formHarvestLog.type === "PARTIAL"
              ? "Harvest_partl_data_save_success"
              : "Harvest_final_hrvst_data_saved_succe"
          ),
          duration: 5000,
          type: "success"
        });
        this.handleCloseDialog("harvest-log-action-" + this.actionToPerform);
        console.log(response);
      } catch (err) {
        const errors = [];
        if (err.response.data.message === "Harvest date should be less than start of cultivation.") {
          errors.push({ message: this.$t("Harvest_date_less_than_soc") });
          this.ehm__errorFailToSave(errors);
        } else if (err.response.data.message) {
          errors.push({ message: this.$t("Harvest_date_greater_than_soc") });
          this.ehm__errorFailToSave(errors);
        } else {
          console.log(err.response.data);
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.el-select-dropdown__empty {
  width: 13rem;
}
.action-on-harvest-log {
  // $width: 80%;
  // .fit-width {
  //   width: $width;
  // }

  .date-picker-harvest-date {
    width: 200px !important;
  }

  .input-abw .el-input__inner {
    text-align: left !important;
  }
  .input-biomass {
    .el-input--small .el-input__inner {
      @include responsiveProperty(width, 120px, 110px, 125px);
    }
    .el-input__inner {
      text-align: left !important;
      padding-inline: 10px;
    }
  }
  .input-biomass-units {
    .el-input--small .el-input__inner {
      @include responsiveProperty(width, 75px, 100px, 120px);
    }
    .el-input__inner {
      padding: 0px;
      padding-right: 26px;
      padding-left: 10px;
    }
  }
  .input-fcr .el-input__inner {
    text-align: left !important;
  }
}
.harvest-action-dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 205px, 227px, 250px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 130px, 160px, 210px);
  }
  .form-survival-error .el-form-item__error {
    width: 200px !important;
  }
}
</style>
